// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import HomeOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/HomeOutlined';
import DollarOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/DollarOutlined';
import IdcardOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/IdcardOutlined';
import BarChartOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/BarChartOutlined';
import LineChartOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/LineChartOutlined';
import UserOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/UserOutlined';
import ApartmentOutlined from '/root/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ApartmentOutlined';
export default { HomeOutlined, DollarOutlined, IdcardOutlined, BarChartOutlined, LineChartOutlined, UserOutlined, ApartmentOutlined };
